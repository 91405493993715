/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.spinninglogo {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
altai-panel {
  display: block;
}
.singlerowcontact {
  padding-bottom: 30px;
}
.altai-theme-content #bodyView {
  background-color: white;
  /*Menu picture*/
  /*margins below form rows*/
  /* start iFrame, maybe not required */
  /*end iFrame*/
  /*start elasticsearcher ui*/
  /*End elasticsearcher ui*/
  /*Bootstrap dropdown hack */
  /*Bootstrap full height cols hack*/
  /*sections with a little padding*/
  /*PC Compare Titles*/
  /*Midmenu styling reset*/
  /*FORMS*/
  /*background-colors*/
}
.altai-theme-content #bodyView .altai-footer-zone {
  background-color: #f3f3f3;
  color: #c3c3c3;
  font-size: 12px;
  padding: 10px 0px 10px 0px;
  text-align: center;
}
.altai-theme-content #bodyView .altai-footer-zone .container {
  padding-bottom: 0px;
}
.altai-theme-content #bodyView .altai-footer-zone a {
  color: #c3c3c3;
}
.altai-theme-content #bodyView .altai-footer-zone a:hover {
  color: #999;
}
.altai-theme-content #bodyView .altai-footer-zone a.accentuate {
  color: black;
}
.altai-theme-content #bodyView .alert-warning-comeos {
  padding: 10px;
}
.altai-theme-content #bodyView .container-content .email-template h1.emailh1 {
  padding: 20px 0px 30px 0;
}
.altai-theme-content #bodyView .menupic {
  width: 100px;
  height: 100px;
  margin: 0 10px 10px 0;
  background-size: cover;
}
.altai-theme-content #bodyView .dossier-contact-section div {
  font-size: 24px;
  font-weight: 400;
}
.altai-theme-content #bodyView .paddedcontainer {
  padding: 60px 0;
}
@media only screen and (max-width: 750px) {
  .altai-theme-content #bodyView h2 {
    font-size: 1.3em;
  }
}
@media only screen and (min-width: 1440px) {
  .altai-theme-content #bodyView .menupic {
    width: 160px;
    height: 160px;
    margin: 0 20px 20px 0;
  }
}
.altai-theme-content #bodyView a.slideshowlink {
  color: white !important;
}
.altai-theme-content #bodyView .form-group {
  margin-bottom: 15px;
}
.altai-theme-content #bodyView iframe {
  width: 100%;
  min-height: 350px;
  border: none;
}
@media all and (max-width: 767px) {
  .altai-theme-content #bodyView iframe {
    min-height: 220px;
  }
}
.altai-theme-content #bodyView .table-borderless tbody tr td,
.altai-theme-content #bodyView .table-borderless tbody tr th,
.altai-theme-content #bodyView .table-borderless thead tr th {
  border: none;
}
.altai-theme-content #bodyView .filter tr {
  background: none !important;
}
.altai-theme-content #bodyView .btn-search-filter {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  border: none;
  background: none;
  font-weight: bold !important;
  line-height: 1em;
  text-transform: capitalize;
  white-space: nowrap;
}
.altai-theme-content #bodyView .btn-search-filter:hover {
  color: #505050 !important;
  cursor: pointer;
}
.altai-theme-content #bodyView .select {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-weight: 700;
  font-size: 0.8em;
  border: none;
  margin: 0;
  white-space: nowrap;
  padding: 10px 18px;
  width: 50%;
  background: #F3F3F3;
}
.altai-theme-content #bodyView .selectcontainer {
  background: #F3F3F3;
}
.altai-theme-content #bodyView .select:hover {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background: #505050;
  color: #FFF;
}
.altai-theme-content #bodyView .select-left {
  margin-right: -2px;
}
.altai-theme-content #bodyView .select-right {
  margin-left: -1px;
  margin-right: -1px;
  text-align: right;
}
.altai-theme-content #bodyView .btn-search-filter.disabled {
  color: gray;
}
.altai-theme-content #bodyView .btn-search-filter.disabled:hover {
  color: inherit !important;
  cursor: default;
}
.altai-theme-content #bodyView .btn-search-filter i {
  display: inline-block;
  vertical-align: middle;
  font-size: 2em;
  line-height: 1em;
}
.altai-theme-content #bodyView .dropdown {
  margin: 10px;
}
.altai-theme-content #bodyView ul.dropdown-menu {
  margin: 0;
  padding: 0;
}
.altai-theme-content #bodyView ul.scroll {
  margin: 0;
  padding: 0;
  min-width: 300px;
  max-height: 500px;
  /* you can change as you need it */
  overflow-y: auto;
  /* to get scroll */
  overflow-x: hidden;
}
.altai-theme-content #bodyView .list-group-item {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  width: 100%;
  min-width: 100%;
  white-space: nowrap;
  display: inline-block;
  margin-right: 50px;
  border: none;
}
.altai-theme-content #bodyView .list-group-item .badge {
  margin-top: 0.3em;
}
.altai-theme-content #bodyView .list-group-item i {
  font-size: 1.2em;
  margin-right: 5px;
}
.altai-theme-content #bodyView .list-group-item:hover {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background: #505050;
  border-color: #505050;
  color: #FFF;
}
.altai-theme-content #bodyView .list-group-item:hover .badge {
  background: #FFF;
  color: #505050;
}
.altai-theme-content #bodyView .list-group-item.active {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  background: #252525 !important;
  border-color: #252525 !important;
  font-weight: bold;
  color: #FFF;
}
.altai-theme-content #bodyView .list-group-item.active .badge {
  background: #FFF;
  color: #505050;
}
.altai-theme-content #bodyView .list-group-item.child {
  font-size: 80%;
  text-indent: 2em;
}
.altai-theme-content #bodyView .list-group-item.child .badge {
  text-indent: 0;
}
.altai-theme-content #bodyView .list-group-item:first-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.altai-theme-content #bodyView .list-group-item:last-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.altai-theme-content #bodyView .search-bar {
  background: #F3F3F3 !important;
  color: #252525;
  font-weight: bold;
  margin: 5px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.altai-theme-content #bodyView input[type="text"].search-bar {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: black;
}
.altai-theme-content #bodyView input[type="text"].search-bar::-webkit-input-placeholder {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView input[type="text"].search-ba:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  color: dimgray;
}
.altai-theme-content #bodyView .dropdown-menu {
  z-index: 98;
}
.altai-theme-content #bodyView .altai-content-form-error {
  background-color: #fff2b8;
  padding: 10px 10px 10px 0;
  margin: 10px 0 30px 0;
}
.altai-theme-content #bodyView .altai-content-form-error::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  /* position: absolute; */
  left: 5%;
  top: -38px;
  position: relative;
  border-bottom: 10px solid #fff2b8;
}
.altai-theme-content #bodyView .row .no-float {
  display: table-cell;
  float: none;
}
.altai-theme-content #bodyView .alittlepaddingsection {
  padding: 20px 80px;
}
.altai-theme-content #bodyView .dossier-vragen .dossier-vraag-item .dossier-vraag-item-vraag.sf {
  /* padding: 0 20px; */
  color: #fff;
  background-color: #EC0044;
}
.altai-theme-content #bodyView .container-content .midmenu ul:not(.menu):not(.slides):not(.accordion):not(.tabs):not(.tabs-content):not(.dropdown-menu) {
  line-height: 1.8em;
  font-size: inherit;
  text-align: center;
  list-style-position: inherit;
}
.altai-theme-content #bodyView .container-content .midmenu ul a {
  text-decoration: none;
  border-bottom: none;
}
.altai-theme-content #bodyView .container-content .midmenu ul a:hover {
  background: none !important;
}
.altai-theme-content #bodyView .altaiform .formsection {
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.altai-theme-content #bodyView .altaiform .inschrijvingheader {
  text-align: center;
  margin-bottom: 50px;
}
.altai-theme-content #bodyView .altaiform .spacing-top {
  margin-top: 30px;
}
.altai-theme-content #bodyView .altaiform .spacing-bottom {
  margin-bottom: 30px;
}
.altai-theme-content #bodyView .altaiform .details {
  font-size: 1.2em;
  font-weight: 600;
}
.altai-theme-content #bodyView .altaiform label {
  font-size: 1em;
  font-weight: 400;
  display: inline-block;
  text-align: left;
  min-width: 150px;
  width: 20%;
}
.altai-theme-content #bodyView .altaiform input[type="text"]:not(button),
.altai-theme-content #bodyView .altaiform input[type="email"]:not(button),
.altai-theme-content #bodyView .altaiform input[type="password"]:not(button),
.altai-theme-content #bodyView .altaiform textarea:not(button),
.altai-theme-content #bodyView .altaiform select:not(button),
.altai-theme-content #bodyView .altaiform input[type="tel"]:not(button),
.altai-theme-content #bodyView .altaiform input[type="url"]:not(button),
.altai-theme-content #bodyView .altaiform input[type="search"]:not(button) {
  background: #fdfdfd;
}
.altai-theme-content #bodyView .altaiform input[type="text"],
.altai-theme-content #bodyView .altaiform input[type="email"],
.altai-theme-content #bodyView .altaiform input[type="password"],
.altai-theme-content #bodyView .altaiform textarea,
.altai-theme-content #bodyView .altaiform select,
.altai-theme-content #bodyView .altaiform input[type="tel"],
.altai-theme-content #bodyView .altaiform input[type="url"],
.altai-theme-content #bodyView .altaiform input[type="search"] {
  padding: 0.8125em;
  border: 2px solid black;
  outline: none;
}
.altai-theme-content #bodyView .altaiform .form-control {
  display: inline-block;
  width: 50%;
  min-width: 250px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fdfdfd;
  background-image: none;
  border: 2px solid #000;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.altai-theme-content #bodyView .altaiform textarea.form-control {
  width: 100%;
  height: 200px;
  padding: 20px 20px;
}
.altai-theme-content #bodyView .altaiform .subform {
  margin-top: 30px;
  background-color: #f3f3f3;
  padding: 30px;
  border-radius: 15px;
}
.altai-theme-content #bodyView .altaiform .subform-row {
  padding-bottom: 30px;
  padding-top: 30px;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #e3e3e3;
}
.altai-theme-content #bodyView .pageheaderlogoimage img {
  width: 250px;
  height: auto;
  margin-bottom: 0;
}
.altai-theme-content #bodyView .header-view {
  background: linear-gradient(90deg, #1687ed, #0069d3);
  background: -webkit-linear-gradient(90deg, #1687ed, #0069d3);
  background: -moz-linear-gradient(90deg, #1687ed, #0069d3);
}
.altai-theme-content #bodyView .comeos-subitem-description p {
  text-align: left;
  font-size: 0.9em;
  font-weight: 300 !important;
  line-height: 1.6em !important;
}
.altai-theme-content #bodyView .container-content span .justclickable {
  cursor: pointer;
  border: 0 solid white;
}
.altai-theme-content #bodyView .container-content span .justclickable:hover {
  background-color: inherit;
}
.altai-theme-content #bodyView .comeos-overview-card {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.altai-theme-content #bodyView .sponsorimage {
  padding-right: 30px;
}
.altai-theme-content #bodyView .altai-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
}
.altai-theme-content #bodyView .bottompaddedtitle {
  padding-bottom: 20px;
}
.altai-theme-content #bodyView .footer-zone-social {
  margin-top: 20px;
}
.altai-theme-content #bodyView .footer-zone-social .social-share-icon {
  padding-right: 20px;
}
.altai-theme-content #bodyView.withfixedmenu {
  margin-top: 105px;
}
.altai-theme-content #bodyView section,
.altai-theme-content #bodyView .section {
  padding-top: 2em;
  padding-bottom: 2em;
}
.altai-theme-content #bodyView section.no-padding-section,
.altai-theme-content #bodyView .section.no-padding-section {
  padding: 0;
  margin: 0;
}
.altai-theme-content #bodyView h1 {
  font-size: 2em !important;
}
.altai-theme-content #bodyView .comeos-buttons {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.altai-theme-content #bodyView .comeos-button {
  color: #000;
  display: inline-block;
  background-color: transparent;
  padding: 10px;
  min-height: 40px;
  max-height: 80px;
  border-radius: 40px;
  border: 2px solid #000;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  transition: background-color 100ms ease-in-out;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
  margin-right: 20px;
}
.altai-theme-content #bodyView .comeos-button.comeos-button-fullwidth {
  width: 100%;
}
.altai-theme-content #bodyView .comeos-button.last {
  margin-right: 0;
}
.altai-theme-content #bodyView .comeos-button.no-pointer {
  cursor: default;
}
.altai-theme-content #bodyView .comeos-button:hover {
  background-color: white;
}
.altai-theme-content #bodyView .comeos-button-white {
  background-color: white;
}
.altai-theme-content #bodyView .comeos-button-white-hover {
  color: white;
  border: 2px solid white;
  background-color: transparent;
  transition: color 300ms ease-in-out;
  transition: background-color 100ms ease-in-out;
}
.altai-theme-content #bodyView .comeos-button.comeos-button-white-hover:hover {
  color: #000;
  background-color: white;
}
.altai-theme-content #bodyView span[altai-link] {
  text-decoration: none;
}
.altai-theme-content #bodyView .comeos-cover {
  background: none;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: white;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.altai-theme-content #bodyView .comeos-cover.highcover {
  height: 420px;
}
.altai-theme-content #bodyView .comeos-cover.sector {
  height: 420px;
}
.altai-theme-content #bodyView .comeos-cover.cover-large {
  padding-top: 50px;
  padding-bottom: 60px;
  min-height: 210px;
  display: flex;
}
.altai-theme-content #bodyView .comeos-cover .title p {
  color: white;
  font-weight: 800;
  font-size: 1em;
  line-height: 1.4em;
  text-align: center;
  margin: 0;
}
.altai-theme-content #bodyView .comeos-cover .breadcrumbs {
  color: white;
  margin-bottom: 30px;
}
.altai-theme-content #bodyView .comeos-cover .breadcrumbs a {
  color: white !important;
}
.altai-theme-content #bodyView .comeos-cover .comeos-button-cover {
  bottom: -20px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 300px;
}
@media screen and (max-width: 700px) {
  .altai-theme-content #bodyView .comeos-cover {
    background-size: cover;
  }
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-green {
  background-color: #19d1c6;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-grey {
  background-color: #EDEDED;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-grey-light {
  background-color: #f3f3f3;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-grey-dark {
  background-color: #999;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-purple {
  background-color: #9677E5;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-blue {
  background-color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-blue-darker {
  background-color: #019de4;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-purple {
  background-color: #9677E5;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-red {
  background-color: #EC0044;
}
.altai-theme-content #bodyView .comeos-cover.comeos-cover-yellow {
  background-color: #FFCE00;
}
.altai-theme-content #bodyView .comeos-bg-theme {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-theme a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-theme {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-sector {
  background-color: #9677E5;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-sector a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-cao {
  color: #EC0044;
}
.altai-theme-content #bodyView .comeos-bg-cao {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-cao a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-sector {
  color: #9677E5;
}
.altai-theme-content #bodyView .comeos-bg-pc {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-pc a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-bg-pcinfo {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-pcinfo a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-bg-onderhandeling {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-onderhandeling a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-onderhandeling {
  color: #EC0044;
}
.altai-theme-content #bodyView .comeos-share-pc {
  color: #EC0044;
}
.altai-theme-content #bodyView .comeos-bg-menuitem {
  background-color: #19d1c6;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-menuitem a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-menuitem {
  color: #19d1c6;
}
.altai-theme-content #bodyView .comeos-bg-region {
  background-color: #FFCE00;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-region a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-region {
  color: #FFCE00;
}
.altai-theme-content #bodyView .comeos-bg-academy {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-academy a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-academy {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-press,
.altai-theme-content #bodyView .comeos-bg-pressrelease {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-press a,
.altai-theme-content #bodyView .comeos-bg-pressrelease a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-pressrelease {
  color: #EC0044;
}
.altai-theme-content #bodyView .comeos-bg-actumessage {
  background-color: #FFCE00;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-actumessage a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-actumessage {
  color: #FFCE00;
}
.altai-theme-content #bodyView .comeos-bg-beleidsniveau {
  background-color: #FFCE00;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-beleidsniveau a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-beleidsniveau {
  color: #FFCE00;
}
.altai-theme-content #bodyView .comeos-bg-workshop {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-workshop a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-workshop,
.altai-theme-content #bodyView .bg--white .comeos-share-workshop i {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-networkingevent {
  background-color: #FFCE00;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-networkingevent a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-networkingevent,
.altai-theme-content #bodyView .bg--white .comeos-share-networkingevent i {
  color: #FFCE00;
}
.altai-theme-content #bodyView .comeos-bg-commissievergadering {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-commissievergadering a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-bg-model {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-model a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-commissievergadering,
.altai-theme-content #bodyView .bg--white .comeos-share-commissievergadering i {
  color: #EC0044;
}
.altai-theme-content #bodyView .comeos-bg-voluntaryengagement {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-voluntaryengagement a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-voluntaryengagement {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-complexdossier {
  background-color: #19d1c6;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-complexdossier a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-complexdossier {
  color: #19d1c6;
}
.altai-theme-content #bodyView .comeos-bg-magazine {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-magazine a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-magazine {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-brochure {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-brochure a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-brochure {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-research {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-research a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-research {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-magazineartikel {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-magazineartikel a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-magazineartikel {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-grafiek {
  background-color: #9677E5;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-grafiek a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-grafiek {
  color: #9677E5;
}
.altai-theme-content #bodyView .comeos-bg-vergelijker1 {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-vergelijker1 a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-vergelijker1 {
  color: #9677E5;
}
.altai-theme-content #bodyView .comeos-bg-job {
  background-color: #EC0044;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-job a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-job {
  color: #EC0044;
}
.altai-theme-content #bodyView .comeos-bg-library {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-library a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-library {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-events {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-events a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-events {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-tools {
  background-color: #00B4E1;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-bg-tools a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-tools {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-about {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-about a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-about {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-commissie {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-commissie a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-commissie {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-green {
  background-color: #19d1c6;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-green a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-green {
  color: #19d1c6;
}
.altai-theme-content #bodyView .comeos-bg-grey {
  background-color: #EDEDED;
  color: #000 !important;
}
.altai-theme-content #bodyView .comeos-bg-grey a {
  color: #000;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-grey {
  color: #EDEDED;
}
.altai-theme-content #bodyView .comeos-bg-grey-light {
  background-color: #f3f3f3;
  color: #000 !important;
}
.altai-theme-content #bodyView .comeos-bg-grey-light a {
  color: #000;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-bg-grey-lightest {
  background-color: #f6f6f6;
  color: #000 !important;
}
.altai-theme-content #bodyView .comeos-bg-grey-lightest a {
  color: #000;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-grey-light {
  color: #f3f3f3;
}
.altai-theme-content #bodyView .comeos-bg-grey-dark {
  background-color: #999;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-grey-dark a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-bg-grey-mid {
  background-color: #e5e5e5;
  color: #000 !important;
}
.altai-theme-content #bodyView .comeos-bg-grey-mid a {
  color: #000;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-grey-dark {
  color: #999;
}
.altai-theme-content #bodyView .comeos-bg-purple {
  background-color: #9677E5;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-purple a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-purple {
  color: #9677E5;
}
.altai-theme-content #bodyView .comeos-bg-blue {
  background-color: #00B4E1;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-blue a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-blue {
  color: #00B4E1;
}
.altai-theme-content #bodyView .comeos-bg-blue-darker {
  background-color: #019de4;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-blue-darker a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-blue-darker {
  color: #019de4;
}
.altai-theme-content #bodyView .comeos-bg-purple {
  background-color: #9677E5;
  color: #fff !important;
}
.altai-theme-content #bodyView .comeos-bg-purple a {
  color: #fff;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-share-purple {
  color: #9677E5;
}
.altai-theme-content #bodyView .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.altai-theme-content #bodyView .profile-picture img {
  border-radius: 10px;
  width: 50px;
  text-align: center;
  /*border: 2px solid;*/
}
.altai-theme-content #bodyView .profile-picture.profile-picture-circle {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  display: inline-block;
}
.altai-theme-content #bodyView .profile-picture.profile-picture-circle img {
  width: 100%;
}
.altai-theme-content #bodyView .comeos-page-intro h1 {
  text-align: center;
}
.altai-theme-content #bodyView .comeos-page-intro p {
  padding-top: 0;
  font-size: 1.1em;
  text-align: justify;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.altai-theme-content #bodyView .title {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  font-size: 2.4em;
  font-weight: 800;
  line-height: 1.6em;
}
.altai-theme-content #bodyView .pretitle {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  line-height: 1.6em;
  margin-bottom: 10px;
}
.altai-theme-content #bodyView .pretitlecontent {
  background-color: white;
  color: #ec0044;
  border-radius: 20px;
  width: initial;
  padding: 5px 20px;
  display: inline-block;
}
.altai-theme-content #bodyView .subtitle {
  font-family: 'Campton', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.6em;
  line-height: 1.6em;
}
.altai-theme-content #bodyView .cke_reset h4 {
  font-size: 2em !important;
}
.altai-theme-content #bodyView .filetype {
  font-size: 1.5em;
  display: inline;
}
.altai-theme-content #bodyView .filetype.filetypepdf {
  color: red;
}
.altai-theme-content #bodyView .filetype.filetypeword {
  color: #004bff;
}
.altai-theme-content #bodyView .filetype.filetypeexcel {
  color: #009744;
}
.altai-theme-content #bodyView .filetype.filetypeppt {
  color: red;
}
.altai-theme-content #bodyView .comeos-button-row {
  display: flex;
  justify-content: center;
  align-content: center;
}
.altai-theme-content #bodyView .comeos-button-row .comeos-button {
  margin: 10px;
}
.altai-theme-content #bodyView .comeos-page-intro section {
  padding-top: 2em;
}
.altai-theme-content #bodyView .event .agendaitemlist .agendaitem {
  margin-top: 15px;
}
.altai-theme-content #bodyView .event .agendaitemlist .agendaitem h5 {
  margin-bottom: 1em;
}
.altai-theme-content #bodyView .event .agendaitemlist .agendaitem h6 {
  margin-top: 20px;
}
.altai-theme-content #bodyView .event .agendaitemlist .agendaitem .agendasubitemlist {
  padding-left: 20px;
}
.altai-theme-content #bodyView .event .agendaitemlist .agendaitem .agendasubitemlist .agendasubitem {
  margin-top: 15px;
  font-size: 0.9em;
}
.altai-theme-content #bodyView .event .sponsorimage img {
  width: 100px;
}
.altai-theme-content #bodyView .event .gmap iframe {
  border-radius: 10px;
}
.altai-theme-content #bodyView .event section {
  /*padding-bottom: 0;*/
}
.altai-theme-content #bodyView .event section.blog-post {
  padding-bottom: 60px;
}
.altai-theme-content #bodyView .event .languagespec {
  margin-bottom: 15px;
}
.altai-theme-content #bodyView .event .languagetitle {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 0.8em;
}
.altai-theme-content #bodyView .event .event-gallery {
  margin-top: 2.2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.altai-theme-content #bodyView .event .event-gallery .event-foto {
  width: 150px;
  margin-left: 20px;
  border-radius: 10px;
}
.altai-theme-content #bodyView .event .left-col {
  margin: 20px 0 20px 0;
}
.altai-theme-content #bodyView .event .right-col {
  margin: 20px 0 20px 0;
  background-color: #f6f6f6;
  border-radius: 15px;
  padding: 10px 35px;
}
.altai-theme-content #bodyView .event table {
  margin-top: 2.2em;
}
.altai-theme-content #bodyView .event table td {
  padding: 10px 15px 10px 15px;
  border: 0px solid white;
  font-size: 0.9em;
  line-height: 1.6em;
  text-align: left;
}
.altai-theme-content #bodyView .event table td.time {
  min-width: 100px;
}
.altai-theme-content #bodyView .event .event-details-tijdstip .moment {
  margin-bottom: 10px;
}
.altai-theme-content #bodyView .event .event-programma .programma-item {
  margin-bottom: 10px;
}
.altai-theme-content #bodyView .event .event-programma .programma-item .time {
  padding-right: 20px;
  color: #343434;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .altai-theme-content #bodyView .event table tr > * {
    display: block;
  }
  .altai-theme-content #bodyView .title {
    font-size: 1.5em;
  }
  .altai-theme-content #bodyView .cktext div,
  .altai-theme-content #bodyView p,
  .altai-theme-content #bodyView .two-col-introduction div {
    text-align: left;
  }
  .altai-theme-content #bodyView section {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .altai-theme-content #bodyView .comeos-highlighted {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .altai-theme-content #bodyView .button-container {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
/*Dropdown hack bootstrap */
@media screen and (max-width: 700px) {
  .altai-theme-content #bodyView .exposed-collection-aggregations {
    padding: 0px;
    margin-bottom: 1.7em;
  }
  .altai-theme-content #bodyView .exposed-collection-aggregations .dropdown {
    margin-left: 0;
    margin-right: 0;
  }
  .altai-theme-content #bodyView .dropdown-menu {
    position: relative;
    top: 0;
    left: 0;
    width: calc(100% - 15px) !important;
    margin: 0px;
  }
  .altai-theme-content #bodyView .dropdown-menu .list-group-item {
    margin-right: 0;
  }
  .altai-theme-content #bodyView .dropdown-menu .scroll {
    width: 100%;
  }
  .altai-theme-content #bodyView .badge {
    margin-right: 15px;
  }
}
